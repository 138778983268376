.jsoninput{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
border: 2px solid #ccc;
}
.jsonoutput{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
border: 2px solid #ccc;
}